import React, { Fragment } from "react"
import styled from "styled-components"
import NumberFormat, { formattedDate } from "../utils/numberformat"
import GoogleWalletPasses from "./GoogleWalletPasses"
import Logo, { LightLogoNew } from "./Logo"
import PrintIcon from "./PrintIcon"
import dompurify from "dompurify"

export const HorizontalLine = styled.hr`
  border: solid 0.07em #2d2d2d;
  @media (max-width: 700px) {
    border: solid 0.5px #2d2d2d;
  }
`

const GiftCertificateHeader = styled.div`
  margin-top: 2em;
  margin-bottom: 1em;
  text-align: center;
`

const GiftCertificateHeaderText = styled.p`
  font-family: "Jost-Regular";
  font-size: 1.2rem;
  @media (max-width: 768px) {
    margin-top: 5rem;
  }
  @media print {
    margin-top: none;
  }
`

const CertificateHeader = props => (
  <GiftCertificateHeader className="gift-certificate-header">
    <GiftCertificateHeaderText>
      You've received an eGift Card
    </GiftCertificateHeaderText>
  </GiftCertificateHeader>
)

const GiftDetailsGrid = styled.div`
  display: grid;
`
const GiftBrandDiv = styled.div`
  font-size: 1.2rem;
  padding-bottom: 0.2em;
  @media (max-width: 700px) {
    text-align: center;
  }
`
const GiftValueDiv = styled.div`
  font-family: "Jost-Bold";
  font-size: 1.5rem;
  @media (max-width: 700px) {
    text-align: center;
    padding-bottom: 0.5em;
  }
`

function GiftCardDetailsHeader(props) {
  return (
    <GiftDetailsGrid className="gift-details-grid">
      <div>
        <GiftBrandDiv>{props.giftDetails.brand_name}</GiftBrandDiv>
        <GiftValueDiv>
          <NumberFormat
            value={props.giftDetails.gift_card_amount}
            currency={true}
          />
        </GiftValueDiv>
      </div>
    </GiftDetailsGrid>
  )
}

const GiftDetailsFooterGrid = styled.div`
  display: grid;
  align-items: end;
  @media (max-width: 700px) {
    text-align: center;
  }
`

const GiftDetailsFooterInnerDiv = styled.div`
  @media (max-width: 700px) {
    grid-row: 2/2;
  }
`

const GiftDetailsFooterItemKeyDiv = styled.div`
  padding-bottom: 0.2em;
`
const GiftDetailsFooterItemKeySpan = styled.span`
  font-size: 0.8rem;
  padding-right: 0.2em;
`

const GiftDetailsFooterItemValue = styled.span`
  font-family: "OpenSans-Bold";
  font-size: 0.8rem;
`

function GiftCardDetailsFooter(props) {
  const footerItems = [
    {
      key: "To",
      value: props.giftDetails.receiver_name,
    },
    {
      key: "Issue Date",
      value: formattedDate(props.giftDetails.issue_date),
    },
    {
      key: "Expiry",
      value: props.giftDetails.expiry,
    },
  ]

  return (
    <GiftDetailsFooterGrid>
      <GiftDetailsFooterInnerDiv>
        {footerItems.map((item, index) => (
          <GiftDetailsFooterItemKeyDiv
            key={`gift-details-footer-item-${index}`}
          >
            <GiftDetailsFooterItemKeySpan>
              {item.key}:
            </GiftDetailsFooterItemKeySpan>
            <GiftDetailsFooterItemValue>
              {item.value}
            </GiftDetailsFooterItemValue>
          </GiftDetailsFooterItemKeyDiv>
        ))}
      </GiftDetailsFooterInnerDiv>
    </GiftDetailsFooterGrid>
  )
}

const GiftDetailsBodyGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const GiftImageWrapperDiv = styled.div`
  display: grid;
  padding: 1.5em 0.5em 1.5em 1em;
  @media (max-width: 700px) {
    padding-bottom: 0.5em;
    justify-items: center;
  }
`

const GiftImageInnerDiv = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    width: 300px;
  }
`

const GiftImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  filter: drop-shadow(-1px 4px 3px rgba(50, 50, 0, 0.5));
  /* @media (max-width: 700px) {
    margin: 2rem;
  } */
`

const GiftInfoGrid = styled.div`
  display: grid;
  padding: 1.5em 1em 1.5em 0.5em;
  @media (max-width: 700px) {
    justify-items: center;
  }
`

const GiftImageAndDetails = props => (
  <GiftDetailsBodyGrid className="gift-certificate-image-giftdetails">
    <GiftImageWrapperDiv className="gift-certificate-image">
      <GiftImageInnerDiv>
        <GiftImage
          src={`${process.env.GATSBY_EGIFTIT_B2B}${props.giftDetails.product_image}`}
          alt={`${props.giftDetails.brand_name}`}
        ></GiftImage>
      </GiftImageInnerDiv>
    </GiftImageWrapperDiv>
    <GiftInfoGrid className="gift-certificate-details">
      <GiftCardDetailsHeader {...props} />
      <GiftCardDetailsFooter {...props} />
    </GiftInfoGrid>
  </GiftDetailsBodyGrid>
)

const BarcodeBlock = styled.div`
  width: 100%;
  height: ${props => (props.giftDetails.barcode ? "10rem" : "2rem")};
  text-align: center;
`

const BarcodeImage = styled.img`
  max-width: 100%;
  height: 100%;
`

export function BarcodeDetails(props) {
  return (
    <BarcodeBlock className="barcode-block" {...props}>
      <BarcodeImage
        src={props.giftDetails.barcode || ""}
        alt={props.giftDetails.online_card_number}
      ></BarcodeImage>
    </BarcodeBlock>
  )
}

const CardBlockDiv = styled.div`
  border: solid 2px #2d2d2d;
  width: 100%;
`

const CardBlockHeader = styled.div`
  background-color: #2d2d2d;
  padding: 0.25rem;
  text-align: center;
`

const CardBlockText = styled.p`
  font-family: "Jost-Bold";
  font-size: 1.2rem;
  color: white;
`

const CardBlockBody = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  text-align: center;
  padding: 1em;
`

const CardBlock = props => (
  <CardBlockDiv className="gift-cardblock">
    <CardBlockHeader className="cardblock-header">
      <CardBlockText>{props.title}</CardBlockText>
    </CardBlockHeader>
    <CardBlockBody className="cardblock-body">{props.children}</CardBlockBody>
  </CardBlockDiv>
)

const TermsAndConditionBlock = styled.div`
  margin: 1em 0;
  text-align: center;
`

const TermsAndConditionLabel = styled.p`
  font-family: "OpenSans-Bold";
  font-size: 0.8rem;
`

const TermsAndConditionText = styled.p`
  font-size: 0.8rem;
  a {
    font-family: "OpenSans-Regular";
    font-size: 0.8rem;
    text-decoration: underline;
    color: var(--button-color);
  }
  ol,
  ul {
    text-align: left;
  }
`

const TermsAndConditions = props => (
  <TermsAndConditionBlock className="gift-certificate-terms-conditions">
    <TermsAndConditionLabel>Terms & Conditions</TermsAndConditionLabel>
    <TermsAndConditionText
      className="termsandconditions__content"
      dangerouslySetInnerHTML={{
        __html: dompurify.sanitize(
          props.giftDetails.terms_and_conditions || "",
          {
            ADD_ATTR: ["target"],
          }
        ),
      }}
    ></TermsAndConditionText>
  </TermsAndConditionBlock>
)

const OnlineInStoreGrid = styled.div`
  display: grid;
  grid-gap: 0.5em;
  grid-template-rows: auto 1fr;
`

const OnlineInStoreLabel = styled.p`
  font-family: "OpenSans-Bold";
  font-size: 0.8rem;
  text-align: ${props =>
    props.onlineInstoreIcons && props.participatingStoreIcons
      ? "left"
      : "center"};
  @media (max-width: 700px) {
    text-align: center;
  }
`

const OnlineInStoredBody = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 2rem;
  justify-content: center;
`

const OnlineInStoreImageDiv = styled.div`
  display: grid;
  align-items: center;
  justify-items: ${props =>
    props.onlineInstoreIcons && props.participatingStoreIcons
      ? "start"
      : "center"};
  @media (max-width: 700px) {
    justify-items: center;
  }
`

const OnlineInstoreImage = styled.img`
  max-width: 100%;
`

function StoreOnlineRetailers(props) {
  return (
    <Fragment>
      {props.onlineInstoreIcons && (
        <OnlineInStoreGrid>
          <OnlineInStoreLabel {...props}>
            Redeemable in store and online at
          </OnlineInStoreLabel>
          {props.giftDetails.online_instore_image && (
            <OnlineInStoredBody>
              <OnlineInStoreImageDiv {...props}>
                <OnlineInstoreImage
                  src={`${process.env.GATSBY_EGIFTIT_B2B}${props.giftDetails.online_instore_image}`}
                  alt={`online-store-image`}
                ></OnlineInstoreImage>
              </OnlineInStoreImageDiv>
            </OnlineInStoredBody>
          )}
        </OnlineInStoreGrid>
      )}
    </Fragment>
  )
}

const ParticipatingStoreGrid = styled.div`
  display: grid;
  grid-template-columns: 2px 1fr;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const VerticalLine = styled.div`
  margin-top: 0.5em;
  border-left: ${props =>
    props.onlineInstoreIcons && props.participatingStoreIcons
      ? "solid 2px #2d2d2d"
      : "none"};
  @media (max-width: 700px) {
    display: none;
  }
`

const ParticipatingStoreLabelDiv = styled.div`
  display: grid;
  grid-gap: 0.5em;
  padding-left: 1em;
  @media (max-width: 700px) {
    padding-top: 1em;
    padding-left: 0;
  }
`

const ParticipatingStoreLabelText = styled.p`
  font-family: "OpenSans-Bold";
  font-size: 0.8rem;
  text-align: ${props =>
    props.onlineInstoreIcons && props.participatingStoreIcons
      ? "left"
      : "center"};
  @media (max-width: 700px) {
    text-align: center;
  }
`

const ParticipatingStoreImageDiv = styled.div`
  display: flex;
  justify-content: center;
`

const ParticipatingStoreImage = styled.img`
  width: 120px;
`

function ParticipatingStoreRetailers(props) {
  return (
    <Fragment>
      {props.participatingStoreIcons && (
        <ParticipatingStoreGrid>
          <VerticalLine {...props}></VerticalLine>
          <ParticipatingStoreLabelDiv>
            <ParticipatingStoreLabelText {...props}>
              Redeemable at participating stores
            </ParticipatingStoreLabelText>
            {props.giftDetails.participating_store_image && (
              <ParticipatingStoreImageDiv>
                <ParticipatingStoreImage
                  src={`${process.env.GATSBY_EGIFTIT_B2B}${props.giftDetails.participating_store_image}`}
                  alt={`participating-store-image`}
                ></ParticipatingStoreImage>
              </ParticipatingStoreImageDiv>
            )}
          </ParticipatingStoreLabelDiv>
        </ParticipatingStoreGrid>
      )}
    </Fragment>
  )
}

const RedeemableInfoGrid = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.onlineInstoreIcons && props.participatingStoreIcons
      ? "1fr 150px"
      : "1fr"};
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const RedeemableAt = props => (
  <RedeemableInfoGrid className="gift-certificate-redeemable-at" {...props}>
    <Fragment>
      <StoreOnlineRetailers {...props} />
      <ParticipatingStoreRetailers {...props} />
    </Fragment>
  </RedeemableInfoGrid>
)

const InstructionsMobileGrid = styled.div`
  text-align: center;
`

const InstructionsMobileText = styled.p`
  font-family: "OpenSans-Bold";
  line-height: 1.2em;
  padding-bottom: 0.5em;
`

const InstructionsMobilePinText = styled.p`
  font-family: "OpenSans-Bold";
  line-height: 1em;
`

const CertificateInstructionsMobileDevices = props => (
  <InstructionsMobileGrid className="gift-certificate-instructions-mobile-devices">
    <p>In Store Card Number</p>
    <InstructionsMobileText>
      {props.giftDetails.instore_card_number}
    </InstructionsMobileText>
    {!props.instoreonlybrandslugs.includes(props.giftDetails.slug) &&
      props.onlineAccess && (
        <Fragment>
          <p>Online Card Number</p>
          <InstructionsMobileText>
            {props.giftDetails.online_card_number}
          </InstructionsMobileText>
        </Fragment>
      )}
    <p>PIN</p>
    <InstructionsMobilePinText>
      {props.giftDetails.pin}
    </InstructionsMobilePinText>
  </InstructionsMobileGrid>
)

const RedemptionInstructionDiv = styled.div`
  text-align: center;
  @media (max-width: 700px) {
    font-size: 0.8rem;
  }
`

const RedemptionInstructionLabel = styled.span`
  font-family: "OpenSans-Bold";
`

function RedemptionInstructions(props) {
  return (
    <RedemptionInstructionDiv>
      <p>
        You can use this eGift Card in store{" "}
        {!props.instoreonlybrandslugs.includes(props.giftDetails.slug) && (
          <Fragment>or online!</Fragment>
        )}{" "}
      </p>
      <p>
        <RedemptionInstructionLabel>In Store:</RedemptionInstructionLabel>
        {` `}Scan the barcode, or enter the In Store Card Number and PIN at
        checkout.
      </p>
      {!props.instoreonlybrandslugs.includes(props.giftDetails.slug) ? (
        <p>
          <RedemptionInstructionLabel>Online:</RedemptionInstructionLabel>
          {` `}Enter the Online Card Number and PIN at checkout online.
        </p>
      ) : (
        <></>
      )}
    </RedemptionInstructionDiv>
  )
}

const CardDetailsText = styled.p`
  font-family: "OpenSans-Bold";
`

function CardDetails(props) {
  return (
    <div>
      {props.giftDetails.instore_card_number ? (
        <span>
          <p>In Store Card Number</p>
          <CardDetailsText>
            {props.giftDetails.instore_card_number}
          </CardDetailsText>
        </span>
      ) : (
        <></>
      )}
      {props.giftDetails.online_card_number &&
      !props.instoreonlybrandslugs.includes(props.giftDetails.slug) ? (
        <span>
          <p>Online Card Number</p>
          <CardDetailsText>
            {props.giftDetails.online_card_number}
          </CardDetailsText>
        </span>
      ) : (
        <></>
      )}
      <p>PIN</p>
      <CardDetailsText>{props.giftDetails.pin || "-"}</CardDetailsText>
    </div>
  )
}

const CertificateInstructionsTitle = styled.p`
  font-family: "OpenSans-Bold";
  font-size: 0.8rem;
  display: none;
  text-align: center;
  @media (max-width: 700px) {
    display: block;
  }
`

const CertificateInstructionsRedemptionSpan = styled.span`
  display: none;
  @media (max-width: 700px) {
    display: block;
  }
`

const CardBlockWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  justify-items: start;
  @media (max-width: 700px) {
    display: none;
  }
`

const CertificateInstructions = props => (
  <div className="gift-certificate-instructions">
    <CertificateInstructionsTitle>
      Redemption Instructions
    </CertificateInstructionsTitle>
    <CertificateInstructionsRedemptionSpan>
      <RedemptionInstructions {...props} />
    </CertificateInstructionsRedemptionSpan>
    <CardBlockWrapper>
      <CardBlock
        title={`Redemption Instructions`}
        giftnumber={props.giftDetails.instore_card_number}
        giftpin={props.giftDetails.pin}
      >
        <RedemptionInstructions {...props} />
      </CardBlock>
      <CardBlock
        title={`Card Details`}
        giftnumber={props.giftDetails.online_card_number}
        giftpin={props.giftDetails.pin}
      >
        <CardDetails {...props} />
      </CardBlock>
    </CardBlockWrapper>
    <TermsAndConditions {...props} />
    <RedeemableAt
      {...props}
      onlineInstoreIcons={props.giftDetails.online_instore_image !== ""}
      participatingStoreIcons={
        props.giftDetails.participating_store_image !== ""
      }
    />
  </div>
)

const CertificateFooterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  align-items: center;
  width: 100%;
  height: 5em;
  background-color: #2d2d2d;
  text-align: center;
`

const CertificateFooterLabel = styled.p`
  font-family: "OpenSans-SemiBold";
  font-size: 0.8rem;
  color: #e6f5f7;
  text-align: right;
`

const LightLogoWrapper = styled.div`
  width: 50px;
  height: 50px;
  @media (max-width: 700px) {
    width: 35px;
    height: 35px;
  }
  @media print {
    display: none;
  }
`

const LogoWrapper = styled.div`
  width: 50px;
  height: 50px;
  @media (max-width: 700px) {
    width: 35px;
    height: 35px;
  }
  @media screen {
    display: none;
  }
`

const CertificateFooter = props => (
  <CertificateFooterGrid className="gift-certificate-footer">
    <CertificateFooterLabel>Powered by</CertificateFooterLabel>
    <LightLogoWrapper>
      <LightLogoNew data={props.data} />
    </LightLogoWrapper>
    <LogoWrapper>
      <Logo data={props.data} />
    </LogoWrapper>
  </CertificateFooterGrid>
)

const PrintButtonComponent = styled.button`
  @media screen {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #2d2d2d;
    width: 100px;
    height: 85px;
    padding: 0.5rem;
    border-bottom-left-radius: 1rem;
    text-align: center;
    outline: none;
    border: none;
    display: grid;
    align-items: center;
    justify-content: center;
    &:active {
      background-color: #2d2d2d80;
      border: 2px solid #2d2d2d80;
    }
  }
  @media print {
    display: none;
  }
`

const PrintButtonText = styled.p`
  font-family: "OpenSans-Bold";
  color: white;
  margin: 0;
`

function PrintButton() {
  return (
    <PrintButtonComponent
      onClick={e => {
        e.preventDefault()
        window.print()
      }}
    >
      <PrintIcon />
      <PrintButtonText>PRINT</PrintButtonText>
    </PrintButtonComponent>
  )
}

const GiftCertificateWrapper = styled.div`
  margin: 0 auto;
  margin-top: 1em;
  width: 700px;
  box-shadow: 0px 2px 5px 0px #2d2d2d;
  position: relative;
  @media (max-width: 767px) {
    width: 100%;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  @media print {
    box-shadow: none;
  }
`

const GiftCertificateInnerDiv = styled.div`
  padding: 1.5em 3em;
  @media (max-width: 767px) {
    width: 100%;
    padding: 1.5em 0 1.5em 0;
  }
`

const HorizontalLineWrapper = styled.div`
  @media (max-width: 700px) {
    display: none;
  }
`

const CertificateMobileWrapper = styled.div`
  display: none;
  @media (max-width: 700px) {
    display: block;
  }
`

export default props => {
  const instoreOnlyBrandSlugs = ["caltex-woolworths", "woolworths-fuel"]

  return (
    <GiftCertificateWrapper className="gift-certificate-wrapper">
      <GiftCertificateInnerDiv className="gift-certificate-div">
        <CertificateHeader />
        <HorizontalLineWrapper>
          <HorizontalLine />
        </HorizontalLineWrapper>
        <GiftImageAndDetails {...props} />
        {props.giftDetails && props.giftDetails.show_barcode && (
          <BarcodeDetails {...props} />
        )}
        <CertificateMobileWrapper>
          <HorizontalLine />
          <CertificateInstructionsMobileDevices
            {...props}
            instoreonlybrandslugs={instoreOnlyBrandSlugs}
            inStoreAccess={true}
            onlineAccess={true}
          />
          <HorizontalLine />
        </CertificateMobileWrapper>
        <CertificateInstructions
          instoreonlybrandslugs={instoreOnlyBrandSlugs}
          inStoreAccess={true}
          onlineAccess={true}
          {...props}
        />
      </GiftCertificateInnerDiv>
      {props.giftDetails.google_wallet_passes_status && (
        <GoogleWalletPasses
          giftDetails={props.giftDetails}
        ></GoogleWalletPasses>
      )}
      <CertificateFooter {...props} />
      <PrintButton />
    </GiftCertificateWrapper>
  )
}
